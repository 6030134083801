var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditJobStyled', [_vm.job ? _c('ModelEditor', {
    staticClass: "edit-job",
    attrs: {
      "title": _vm.title,
      "model": _vm.job,
      "errors": _vm.errors,
      "canSave": false,
      "loading": _vm.$apollo.loading
    },
    on: {
      "save": _vm.update,
      "cancel": _vm.restore
    }
  }, [_c('UpdateJobStyled', [_c('InputField', {
    staticClass: "id",
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.job.id,
            expression: "job.id",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "disabled": true
          },
          domProps: {
            "value": _vm.job.id
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.job, "id", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("ID")])];
      },
      proxy: true
    }], null, false, 3955010350)
  }), _c('div', {
    staticClass: "calendar"
  }, [_c('h4', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('calendar', 1))) + ":")]), _vm.job.schedule && _vm.job.schedule.calendar ? _c('router-link', {
    attrs: {
      "to": {
        name: 'calendar',
        params: {
          id: _vm.job.schedule.calendar.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.job.schedule.calendar.name) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "schedule"
  }, [_c('h4', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('schedule', 1))) + ":")]), _vm.job.schedule ? _c('router-link', {
    attrs: {
      "to": {
        name: 'calendar',
        params: {
          id: _vm.job.schedule.calendar.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.job.schedule.name) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "created-at"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('createdAt')) + ":")]), _c('div', [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.job.createdAt)) + " ")])]), _c('div', {
    staticClass: "run-at"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('runAt')) + ":")]), _c('div', [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.job.runAt)) + " "), _c('div', [_c('a', {
    attrs: {
      "href": "#reschedule"
    },
    on: {
      "click": function click($event) {
        _vm.showReschedule = true;
      }
    }
  }, [_vm._v(" reschedule ")])])])]), false ? _c('div', {
    staticClass: "custom-select"
  }, [_c('CustomSingleSelect', {
    staticClass: "select-schedule",
    attrs: {
      "track-by": "id",
      "options": _vm.availableSchedules,
      "selectedOption": _vm.selectedSchedule,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "placeholder": 'Select Schedule'
    },
    on: {
      "selectedFilter": _vm.selectSchedule
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('schedule', 1)) + ":")])];
      },
      proxy: true
    }], null, false, 1090106485)
  })], 1) : _vm._e()], 1), _c('div', [_c('div', {
    staticClass: "current-shift"
  }, [_c('h4', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('dashboard.shift', 1))))]), _c('div', [_c('span', [_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.currentShiftStart)))]), _c('span', [_vm._v(" - ")]), _c('span', [_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.currentShiftEnd)))])])]), _c('div', {
    staticClass: "previous-shift"
  }, [_c('h4', [_vm._v("compared " + _vm._s(_vm._f("capitalize")(_vm.$tc('dashboard.shift', 1))))]), _c('div', [_c('span', [_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.previousShiftStart)))]), _c('span', [_vm._v(" - ")]), _c('span', [_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.previousShiftEnd)))])])]), _c('div', {
    staticClass: "queue-run-at"
  }, [_c('h4', [_vm._v("queue run at")]), _vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.job.queueRunAt)) + " ")]), _c('div', {
    staticClass: "queue-timestamp"
  }, [_c('h4', [_vm._v("queue timestamp")]), _vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.job.queueTimestamp)) + " ")])])], 1) : _vm._e(), _vm.showReschedule ? _c('RescheduleStyled', {
    staticClass: "reschedule"
  }, [_c('header', [_c('h3', {
    attrs: {
      "id": "reschedule"
    }
  }, [_vm._v("Reschedule " + _vm._s(_vm.$tc('job', 1)))])]), _c('main', [_c('div', {
    staticClass: "select-month"
  }, [_c('button', {
    on: {
      "click": _vm.reschedulePrevMonth
    }
  }, [_c('ChevronLeftIcon')], 1), _c('span', [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.rescheduleSelectedMonth, {
    month: 'long'
  })) + " " + _vm._s(_vm._f("formatDateTime")(_vm.rescheduleSelectedMonth, {
    year: 'numeric'
  })) + " ")]), _c('button', {
    on: {
      "click": _vm.rescheduleNextMonth
    }
  }, [_c('ChevronRightIcon')], 1)]), _c('div', {
    staticClass: "occurrences"
  }, _vm._l(_vm.occurrences, function (occurrence, i) {
    return _c('div', {
      key: i
    }, [_c('span', {
      staticClass: "occurrence",
      on: {
        "click": function click($event) {
          return _vm.confirmRescheduleJob(occurrence);
        }
      }
    }, [_c('span', {
      staticClass: "start"
    }, [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(occurrence.start)) + " ")]), _c('span', [_vm._v(" - ")]), _c('span', {
      staticClass: "end"
    }, [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(occurrence.end)) + " ")])])]);
  }), 0)])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }