<template>
  <EditJobStyled>
    <ModelEditor
      v-if="job"
      class="edit-job"
      :title="title"
      :model="job"
      :errors="errors"
      :canSave="false"
      :loading="$apollo.loading"
      @save="update"
      @cancel="restore"
    >
      <UpdateJobStyled>
        <InputField class="id">
          <template v-slot:input>
            <input type="text" v-model.trim="job.id" :disabled="true" />
          </template>
          <template v-slot:label>
            <span>ID</span>
          </template>
        </InputField>
        <div class="calendar">
          <h4>{{ $tc('calendar', 1) | capitalize }}:</h4>
          <router-link v-if="job.schedule && job.schedule.calendar" :to="{ name: 'calendar', params: { id: job.schedule.calendar.id } }">
            {{ job.schedule.calendar.name }}
          </router-link>
        </div>
        <div class="schedule">
          <h4>{{ $tc('schedule', 1) | capitalize }}:</h4>
          <router-link v-if="job.schedule" :to="{ name: 'calendar', params: { id: job.schedule.calendar.id } }">
            {{ job.schedule.name }}
          </router-link>
        </div>
        <div class="created-at">
          <h4>{{ $t('createdAt') }}:</h4>
          <div>
            {{ job.createdAt | formatDateTime }}
          </div>
        </div>
        <div class="run-at">
          <h4>{{ $t('runAt') }}:</h4>
          <div>
            {{ job.runAt | formatDateTime }}
            <div>
              <a href="#reschedule" @click="showReschedule = true"> reschedule </a>
            </div>
          </div>
        </div>
        <div class="custom-select" v-if="false">
          <CustomSingleSelect
            class="select-schedule"
            track-by="id"
            :options="availableSchedules"
            :selectedOption="selectedSchedule"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
            :placeholder="'Select Schedule'"
            @selectedFilter="selectSchedule"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $tc('schedule', 1) }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
        </div>
      </UpdateJobStyled>
      <div>
        <div class="current-shift">
          <h4>{{ $tc('dashboard.shift', 1) | capitalize }}</h4>
          <div>
            <span>{{ currentShiftStart | formatDateTime }}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{{ currentShiftEnd | formatDateTime }}</span>
          </div>
        </div>
        <div class="previous-shift">
          <h4>compared {{ $tc('dashboard.shift', 1) | capitalize }}</h4>
          <div>
            <span>{{ previousShiftStart | formatDateTime }}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{{ previousShiftEnd | formatDateTime }}</span>
          </div>
        </div>
        <div class="queue-run-at">
          <h4>queue run at</h4>
          {{ job.queueRunAt | formatDateTime }}
        </div>
        <div class="queue-timestamp">
          <h4>queue timestamp</h4>
          {{ job.queueTimestamp | formatDateTime }}
        </div>
      </div>
    </ModelEditor>
    <RescheduleStyled class="reschedule" v-if="showReschedule">
      <header>
        <h3 id="reschedule">Reschedule {{ $tc('job', 1) }}</h3>
      </header>
      <main>
        <div class="select-month">
          <button @click="reschedulePrevMonth">
            <ChevronLeftIcon />
          </button>
          <span>
            {{ rescheduleSelectedMonth | formatDateTime({ month: 'long' }) }}
            {{ rescheduleSelectedMonth | formatDateTime({ year: 'numeric' }) }}
          </span>
          <button @click="rescheduleNextMonth">
            <ChevronRightIcon />
          </button>
        </div>
        <div class="occurrences">
          <div v-for="(occurrence, i) in occurrences" :key="i">
            <span class="occurrence" @click="confirmRescheduleJob(occurrence)">
              <span class="start">
                {{ occurrence.start | formatDateTime }}
              </span>
              <span>&nbsp;-&nbsp;</span>
              <span class="end">
                {{ occurrence.end | formatDateTime }}
              </span>
            </span>
          </div>
        </div>
      </main>
    </RescheduleStyled>
  </EditJobStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelEditor from '@/components/misc/ModelEditor'
import { DateTime } from 'luxon'
import chroma from 'chroma-js'
import { CustomSingleSelect, InputField } from '@common/components'
import { Schedule } from '@rschedule/core/generators'

import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'

import JOB_QUERY from '#/graphql/calendar/job/show.gql'
import RESCHEDULE_JOB_MUTATION from '#/graphql/calendar/job/reschedule.gql'

const EditJobStyled = styled('div')`
  display: grid;
  .edit-job {
    > .main {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .run-at {
    > div {
      margin-top: 0.5rem;
    }
  }
  h4 {
    margin-bottom: 0.5rem;
  }
`

const RescheduleStyled = styled('div')`
  display: grid;
  margin: 2rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;

  > header {
    padding: 1rem;
    h3 {
      margin: 0;
    }
  }

  > main {
    padding: 1rem;
    min-height: 600px;
    background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};

    .select-month {
      display: grid;
      grid-template-columns: 3rem 18rem 3rem;
      grid-template-rows: 3rem;
      align-items: center;
      justify-items: center;
      user-select: none;
      > button {
        cursor: pointer;
        background: transparent;
        outline: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
    .occurrences {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 1.5rem;
      .occurrence {
        cursor: pointer;
        user-select: none;
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const UpdateJobStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  .custom-select {
    max-width: 30rem;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};

    .multiselect__content .multiselect__element .multiselect__option.multiselect__option--highlight {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.tableWidgetHightlight};

      &.multiselect__option--disabled {
        background: ${({ theme }) => theme.colors.tableWidgetHightlight};
      }
    }
  }
`

export default {
  components: {
    EditJobStyled,
    UpdateJobStyled,
    ModelEditor,
    CustomSingleSelect,
    ShowAsLabel,
    InputField,
    RescheduleStyled,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      showReschedule: false,
      name: '',
      alias: '',
      errors: [],
      job: null,
      title: this.$tc('job', 1),
      selectedSchedule: null,
      availableSchedules: [],
      rescheduleSelectedMonth: DateTime.local().startOf('month'),
    }
  },
  computed: {
    canSave() {
      // return !this.$v.$invalid && !this.isUpdating && Boolean(this.hasOwner)
      return true
    },
    rschedule() {
      if (this.job?.schedule?.recurrences?.length > 0) {
        return new Schedule({
          rrules: this.job?.schedule?.recurrences.map(recurrence => {
            const r = {
              start: DateTime.fromISO(recurrence.start),
              frequency: recurrence.frequency,
              duration: recurrence.duration,
              interval: recurrence.interval ? recurrence.interval : undefined,
              count: recurrence.count ? recurrence.count : undefined,
              weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
              bySecondOfMinute: recurrence.bySecondOfMinute.length > 0 ? recurrence.bySecondOfMinute : undefined,
              byMinuteOfHour: recurrence.byMinuteOfHour.length > 0 ? recurrence.byMinuteOfHour : undefined,
              byHourOfDay: recurrence.byHourOfDay.length > 0 ? recurrence.byHourOfDay : undefined,
              byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
              byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
              byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
            }
            return r
          }),
        })
      } else {
        return null
      }
    },
    occurrences() {
      if (this.rschedule) {
        const start = this.rescheduleSelectedMonth.startOf('month')
        const end = this.rescheduleSelectedMonth.endOf('month')
        return this.getOccurrencesForSchedule(this.rschedule, start, end)
      } else {
        return []
      }
    },
    currentShiftStart() {
      return this.job?.scheduleOccurrence ? DateTime.fromISO(this.job.scheduleOccurrence) : null
    },
    duration() {
      return this.job?.schedule?.recurrences[0]?.duration || 0
    },
    currentShiftEnd() {
      if (this.duration > 0) {
        return this.job?.scheduleOccurrence ? DateTime.fromISO(this.job.scheduleOccurrence).plus(this.duration) : null
      } else {
        return null
      }
    },
    previousShiftStart() {
      return this.job?.previousScheduleOccurrence ? DateTime.fromISO(this.job.previousScheduleOccurrence) : null
    },
    previousShiftEnd() {
      if (this.duration > 0) {
        return this.job?.previousScheduleOccurrence ? DateTime.fromISO(this.job.previousScheduleOccurrence).plus(this.duration) : null
      } else {
        return null
      }
    },
  },
  methods: {
    restore() {},
    update() {},
    getOccurrencesForSchedule(rschedule, start, end) {
      if (!rschedule) {
        return []
      }
      const occurrences = rschedule.occurrences({
        start,
        end,
      })
      return occurrences.toArray().map((m, i) => {
        return {
          position: i,
          start: m.date.toUTC(),
          end: m.date.plus(this.duration),
        }
      })
    },
    selectSchedule(schedule) {
      this.selectedSchedule = schedule
    },
    reschedulePrevMonth() {
      this.rescheduleSelectedMonth = this.rescheduleSelectedMonth.minus({ month: 1 }).startOf('month')
    },
    rescheduleNextMonth() {
      this.rescheduleSelectedMonth = this.rescheduleSelectedMonth.plus({ month: 1 }).startOf('month')
    },
    confirmRescheduleJob(occurrence) {
      const resultingJobs = this.getOccurrencesForSchedule(this.rschedule, occurrence?.start, DateTime.utc())
      this.$root.$emit(
        'activateOverlay',
        'ConfirmOverlay',
        {
          confirmText: `
            <p>
            Are you sure you want to reschedule job '${this.job?.id}' to:
            <br>${occurrence?.start?.toLocaleString(DateTime.DATETIME_FULL)} - ${occurrence?.end?.toLocaleString(DateTime.DATETIME_FULL)}
            </p>
            <p>
            This will result in ~${resultingJobs.length} new jobs that will be triggered immediately after confirming.
          `,
          onConfirm: this.rescheduleJob,
          onConfirmArgs: occurrence,
        },
        this.onCloseSettings,
      )
    },
    async rescheduleJob(occurrence) {
      if (!this.job?.id || !occurrence?.start) {
        return
      }
      const res = await this.$apollo.mutate({
        mutation: RESCHEDULE_JOB_MUTATION,
        variables: {
          data: {
            startAt: occurrence.start.toISO(),
          },
          where: {
            id: this.job?.id,
          },
        },
      })
      return !!res?.data
    },
  },
  apollo: {
    job: {
      query: JOB_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params?.id,
          },
        }
      },
      skip() {
        return !this.$route.params?.id
      },
      manual: true,
      result({ data }) {
        this.job = data?.job
      },
    },
  },
}
</script>
